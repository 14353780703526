import { graphql } from 'gatsby';
import * as React from 'react';
import { Link, useTranslation, useI18next } from 'gatsby-plugin-react-i18next';
import BaseLayout from '../components/layouts/base-layout';
import { Container, Row, Col, ListGroup } from 'react-bootstrap';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import * as style from '../styles/index.module.scss';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import PrimaryButton from '../components/atomics/buttons/primary-button';
import SecondaryButton from '../components/atomics/buttons/secondary-button';
import LayerCard from '../components/atomics/cards/layer-card';
import BrandLayout from '../components/layouts/brand-layout';
import HeaderLayout from '../components/layouts/header-layout';
import { forwardToAppLink } from '../helpers/pathConverter';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import BannerImageCard from '../components/atomics/cards/banner-image-card';
import RightLine  from '../images/line.png';
import TertiaryButton from '../components/atomics/buttons/tertiary-button';
import PrimaryCard from '../components/atomics/cards/primary-card';
import CustomDots from '../components/atomics/buttons/custom-dots';
interface MyQueryResult {
  logos: {
    edges: {
      node: {
        relativePath: any;
        id: string;
        base: string;
        childImageSharp: {
          gatsbyImageData: any;
        };
      };
    }[];
  };
}
const IndexPage = ({ data }: { data: MyQueryResult }) => {
  const { t } = useTranslation();
  const { language } = useI18next();

  const slides = [
    {
      id: 0,
      key: '1',
      className: 'bgprimary02',
      textStart: t('reason.textstart1'),
      textPoint: t('reason.textpoint1'),
      textPointClass: style.pointprimary,
      textEnd: t('reason.textend1'),
      textClass: style.layertext
    },
    {
      id: 1,
      key: '2',
      className: 'bgsecondary02',
      textStart: t('reason.textstart2'),
      textPoint: t('reason.textpoint2'),
      textPointClass: style.pointsecondary,
      textEnd: t('reason.textend2'),
      textClass: style.layertext
    },
    {
      id: 2,
      key: '3',
      className: 'bgsecondary02',
      textStart: t('reason.textstart3'),
      textPoint: t('reason.textpoint3'),
      textPointClass: style.pointsecondary,
      textEnd: t('reason.textend3'),
      textClass: style.layertext
    },
    {
      id: 3,
      key: '4',
      className: 'bgsecondary02',
      textStart: t('reason.textstart4'),
      textPoint: t('reason.textpoint4'),
      textPointClass: style.pointsecondary,
      textEnd: t('reason.textend4'),
      textClass: style.layertext
    },
    {
      id: 4,
      key: '5',
      className: 'bgsecondary02',
      textStart: t('reason.textstart5'),
      textPoint: t('reason.textpoint5'),
      textPointClass: style.pointsecondary,
      textEnd: t('reason.textend5'),
      textClass: style.layertext
    }
  ];

  return (
    <BaseLayout>
      <HeaderLayout
        backgroundImage={RightLine}
        head={t('fenomio.head')}
        text={t('fenomio.bannerText')}
        image={
          <BannerImageCard
            backgroundColor={'#F1EDFF'}
            image={<StaticImage src="../images/banner-image.png" alt={'banner'} className={'img-fluid'}></StaticImage>}
          ></BannerImageCard>
        }
      >
        <Row>
          <Col xs="6" md="6">
            <SecondaryButton onClick={() => (window.location.href = forwardToAppLink('signup', language, 'brand'))} head={t('fenomio.brand')}></SecondaryButton>
          </Col>
          <Col xs="6" md="6">
            <PrimaryButton
              onClick={() => (window.location.href = forwardToAppLink('signup', language, 'influencer'))}
              head={t('fenomio.influencer')}
            ></PrimaryButton>
          </Col>
        </Row>
      </HeaderLayout>
      <div className={style.bannerC}>
        <h2 className="text-center">{t('slider.brands')}</h2>
        <div className={style.logoCarousel}>
          <Carousel
            additionalTransfrom={0}
            arrows={false}
            autoPlaySpeed={3000}
            centerMode
            autoPlay
            customTransition='3s linear'
            className={style.logoSlider}
            containerClass="container"
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            pauseOnHover
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 3000,
                  min: 1024
                },
                items: 6,
                partialVisibilityGutter: 40
              },
              mobile: {
                breakpoint: {
                  max: 767,
                  min: 0
                },
                items: 2,
                partialVisibilityGutter: 30
              },
              tablet: {
                breakpoint: {
                  max: 1024,
                  min: 464
                },
                items: 4,
                partialVisibilityGutter: 20
              }
            }}
            rewind={true}
            rewindWithAnimation={false}
            rtl={false}
            shouldResetAutoplay
            showDots={false}
            sliderClass=""
            slidesToSlide={1}
            swipeable
          >
            {data.logos.edges.map((image) => (
              <div className={style.logo}>
                <GatsbyImage
                  className={style.logoImage}
                  alt={''} // only use section of the file extension with the filename
                  image={image.node.childImageSharp.gatsbyImageData}
                />
              </div>
            ))}
          </Carousel>
        </div>
      </div>
      <div className={style.bannerC}>
        <h2 className="text-center">{t('brandlist.head1')}</h2>
        <div>
          <Container>
            <Row className={style.header}>
              <Col xs="12" md="6">
                <Col md="12" className="bgsecondary02">
                  <p className="plight">{t('brandlist.text1')}</p>
                  <h3 className="hlight">{t('brandlist.head2')}</h3>
                  <ListGroup className="listgrps">
                    <ListGroup.Item className="listItem">
                      <Row>
                        <Col md="1" xs="1">
                          <BsFillCheckCircleFill size="1.5rem" />
                        </Col>
                        <Col>{t('brandlist.item1')}</Col>
                      </Row>
                    </ListGroup.Item>
                    <ListGroup.Item className="listItem">
                      <Row>
                        <Col md="1" xs="1">
                          <BsFillCheckCircleFill size="1.5rem" />{' '}
                        </Col>
                        <Col>{t('brandlist.item2')}</Col>
                      </Row>
                    </ListGroup.Item>
                    <ListGroup.Item className="listItem">
                      <Row>
                        <Col md="1" xs="1">
                          <BsFillCheckCircleFill size="1.5rem" />
                        </Col>
                        <Col> {t('brandlist.item3')}</Col>
                      </Row>
                    </ListGroup.Item>
                    <ListGroup.Item className="listItem">
                      <Row>
                        <Col md="1" xs="1">
                          <BsFillCheckCircleFill size="1.5rem" />
                        </Col>
                        <Col>{t('brandlist.item4')}</Col>
                      </Row>
                    </ListGroup.Item>
                  </ListGroup>
                  <Row>
                    <Col md="12">
                      <TertiaryButton
                        style={{ width: 'max-content', paddingRight: '2.5rem', paddingLeft: '2.5rem' }}
                        onClick={() => (window.location.href = forwardToAppLink('signup', language, 'brand'))}
                        chevron
                        head={t('fenomio.brand-signup')}
                      ></TertiaryButton>
                    </Col>
                    <Col className="mt-4">
                      <Link to={'/brand'} className="trlink">
                        {t('fenomio.link')}
                      </Link>
                    </Col>
                  </Row>
                </Col>
              </Col>
              <Col xs="12" md="6">
                <Col md="12" className="bgprimary02">
                  <p className="plight">{t('fenomenlist.text1')}</p>
                  <h3 className="hlight">{t('fenomenlist.head2')}</h3>
                  <ListGroup className="listgrp">
                    <ListGroup.Item className="listItem">
                      <Row>
                        <Col md="1" xs="1">
                          <BsFillCheckCircleFill size="1.5rem" />
                        </Col>
                        <Col>{t('fenomenlist.item1')}</Col>
                      </Row>
                    </ListGroup.Item>
                    <ListGroup.Item className="listItem">
                      <Row>
                        <Col md="1" xs="1">
                          {' '}
                          <BsFillCheckCircleFill size="1.5rem" />
                        </Col>
                        <Col>{t('fenomenlist.item2')}</Col>
                      </Row>
                    </ListGroup.Item>
                    <ListGroup.Item className="listItem">
                      <Row>
                        <Col md="1" xs="1">
                          <BsFillCheckCircleFill size="1.5rem" />
                        </Col>
                        <Col>{t('fenomenlist.item3')}</Col>
                      </Row>
                    </ListGroup.Item>
                    <ListGroup.Item className="listItem">
                      <Row>
                        <Col md="1" xs="1">
                          <BsFillCheckCircleFill size="1.5rem" />{' '}
                        </Col>
                        <Col>{t('fenomenlist.item4')}</Col>
                      </Row>
                    </ListGroup.Item>
                  </ListGroup>
                  <Row>
                    <Col>
                      <PrimaryButton
                        style={{ width: 'max-content', paddingRight: '2.5rem', paddingLeft: '2.5rem' }}
                        onClick={() => (window.location.href = forwardToAppLink('signup', language, 'influencer'))}
                        chevron
                        head={t('fenomio.influencer-signup')}
                      ></PrimaryButton>
                    </Col>
                    <Col md="12" className="mt-4">
                      <Link to={'/fenomen'} className="prlink">
                        {t('fenomio.link')}
                      </Link>
                    </Col>
                  </Row>
                </Col>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div className={style.bannerC + ' ' + style.bgLineLeft}>
        <div>
          <Container>
            <Row className="justify-content-md-center">
              <Col md="6">
                <h2 className="text-center">{t('testimonial.head1')}</h2>
              </Col>
            </Row>
          </Container>
          <div className={style.bgLineRight}>
            <BrandLayout
              company={t('brand.company')}
              count={t('brand.count')}
              testimonial={t('brand.testimonial')}
              text={t('brand.text')}
              color="blue"
            ></BrandLayout>
            <BrandLayout
              company={t('fenomen.company')}
              count={t('fenomen.count')}
              testimonial={t('fenomen.testimonial')}
              text={t('fenomen.text')}
              color="pink"
            ></BrandLayout>
          </div>
        </div>
      </div>
      <div className={style.bannerC}>
        <Container>
          <Row className="justify-content-md-center">
            <Col md="6">
              <h2 className="text-center">{t('brands.head1')}</h2>
            </Col>
          </Row>
        </Container>
        <Carousel
          additionalTransfrom={0}
          autoPlay
          autoPlaySpeed={3000}
          centerMode={false}
          className={style.testimonialSlider}
          containerClass="container"
          dotListClass=""
          draggable
          focusOnSelect={false}
          infinite
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          pauseOnHover
          renderArrowsWhenDisabled={false}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 1024
              },
              items: 2,
              partialVisibilityGutter: 40
            },
            mobile: {
              breakpoint: {
                max: 464,
                min: 0
              },
              items: 1,
              partialVisibilityGutter: 30
            },
            tablet: {
              breakpoint: {
                max: 1024,
                min: 464
              },
              items: 2,
              partialVisibilityGutter: 30
            }
          }}
          rewind={false}
          rewindWithAnimation={false}
          rtl={false}
          shouldResetAutoplay
          showDots={false}
          sliderClass=""
          slidesToSlide={1}
          swipeable
        >
          <div className={style.testimonial}><PrimaryCard bg="white" text={t('testimonials.cambly')} /></div>
          <div className={style.testimonial}><PrimaryCard bg="white" text={t('testimonials.amazon')} /></div>
          <div className={style.testimonial}><PrimaryCard bg="white" text={t('testimonials.trendyol')} /></div>
          <div className={style.testimonial}><PrimaryCard bg="white" text={t('testimonials.gittigidiyor')} /></div>
        </Carousel>
      </div>
      <div className={style.bannerC}>
        <Row className="justify-content-center ">
          <Col md="5" xs="8">
            <h2 className="text-center">{t('reason.head1')}</h2>
          </Col>
        </Row>
        <Container>
          <Row className={style.verticalCarouselRow}>
            <Col lg={{ span: 4, offset: 2 }}>
              <Carousel
                additionalTransfrom={0}
                arrows={false}
                autoPlay
                autoPlaySpeed={5000}
                centerMode={false}
                className=""
                containerClass="verticalCarousel"
                dotListClass="verticalDots"
                draggable
                customTransition="none"
                focusOnSelect={false}
                customDot={<CustomDots />}
                infinite
                itemClass="verticalSliteItems"
                keyBoardControl
                minimumTouchDrag={80}
                pauseOnHover
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={{
                  desktop: {
                    breakpoint: {
                      max: 3000,
                      min: 1024
                    },
                    items: 1
                  },
                  mobile: {
                    breakpoint: {
                      max: 464,
                      min: 0
                    },
                    items: 1
                  },
                  tablet: {
                    breakpoint: {
                      max: 1024,
                      min: 464
                    },
                    items: 1
                  }
                }}
                rewind={false}
                rewindWithAnimation={false}
                rtl={false}
                shouldResetAutoplay
                showDots
                sliderClass=""
                slidesToSlide={1}
                swipeable
              >
                {slides.map((slide) => (
                  <div key={slide.key}>
                    <LayerCard
                      className={slide.className}
                      textStart={slide.textStart}
                      textPoint={slide.textPoint}
                      textPointClass={slide.textPointClass}
                      textEnd={slide.textEnd}
                      textClass={slide.textClass}
                    />
                  </div>
                ))}
              </Carousel>
            </Col>
            <Col lg={{ span: 6 }} className={style.verticalCarouselImageContainer}>
              <StaticImage className={style.verticalCarouselImage} src="../images/social-media.png" alt={''}></StaticImage>
            </Col>
          </Row>
        </Container>
      </div>
    </BaseLayout>
  );
};
export default IndexPage;
export const query = graphql`
  query MyQuery($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    logos: allFile(filter: { extension: { regex: "/(jpg)|(png)|(jpeg)/" }, relativeDirectory: { eq: "logos" } }) {
      edges {
        node {
          id
          relativePath
          relativeDirectory
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
