import React from 'react';
import { BiChevronRight } from 'react-icons/bi';
import * as style from './secondary-button.module.scss';
type SecondaryButtonProps = {
  head: string;
  chevron?: boolean;
} & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

const SecondaryButton = (props: SecondaryButtonProps) => {
  return (
    <button className={style.secondaryButton} {...props}>
      {props.head}
      {props.chevron && <BiChevronRight size="1.5rem" />}
    </button>
  );
};

export default SecondaryButton;
